<template>
  <div style="padding: 20px">
    <div style="margin-bottom: 20px;">
      <el-row align="flex-end">
        <el-col>
          <div style="text-align: right">
            <el-button type="primary" size="default" @click="createPosition">
              创建轮播位
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list" border stripe row-key="id">
      <el-table-column label="项目名" prop="project_name"></el-table-column>
      <el-table-column label="轮播位Key" prop="position_id"></el-table-column>
      <el-table-column label="轮播位名称" prop="position_name"></el-table-column>
      <el-table-column label="高度限制(px)" prop="height"></el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.row)"
            style="margin-right: 10px"
          >
            编辑轮播位
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="handleBanner(scope.row)"
            style="margin-right: 10px"
          >
            轮播图管理
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog :visible.sync="showAdd" width="50%" @close="clearPosition" destroy-on-close>
      <el-form ref="form" :model="postForm" label-width="120px" :rules="rules">
        <el-form-item label="项目名" prop="project_id">
          <el-select v-model="postForm.project_id" placeholder="请选择">
            <el-option v-for="item in products" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="轮播位名称" prop="position_name">
          <el-input v-model="postForm.position_name"></el-input>
        </el-form-item>
        <el-form-item label="高度限制(px)" prop="height">
          <el-input v-model.number="postForm.height"></el-input>
        </el-form-item>
        <el-form-item label="轮播位key" prop="position_id">
          <el-input v-model="postForm.position_id"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="clearPosition">取 消</el-button>
        <el-button type="primary" @click="submitPosition">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getBannerPositionList, editBannerPosition } from "@/api/nft.js";
import { getActiveProducts } from "@/api/active";

export default {
  components: {},
  data() {
    return {
      page: 1,
      pageSize: 20,
      list: [],
      total: 0,
      postForm: {},
      showAdd: false,
      products: [],
      currentId: "",
      rules: {
        project_id: [{ required: true, message: "请选择项目", trigger: "blur" }],
        position_name: [{ required: true, message: "请输入轮播位名", trigger: "blur" }],
        height: [
          { required: true, message: "请输入高度限制", trigger: "blur" },
          { type: "number", message: "高度值为数字值", trigger: "change" },
        ],
        position_id: [{ required: true, message: "请输入轮播位Key", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.fetchData();
    this.fetchProducts();
  },
  methods: {
    async fetchData() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
        };
        const { data } = await getBannerPositionList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchProducts() {
      try {
        const { data } = await getActiveProducts();
        this.products = data.data.items;
        console.log("fetchProducts", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    handleEdit(data) {
      this.postForm = {
        project_id: data.project_id,
        position_name: data.position_name,
        height: data.height,
        position_id: data.position_id,
      };
      this.currentId = data.id;
      this.showAdd = true;
    },
    handleBanner(data) {
      this.$router.push(`/nft_content/banner/${data.position_id}`);
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    clearPosition() {
      this.showAdd = false;
      this.currentId = "";
      this.postForm = {};
    },
    createPosition() {
      this.showAdd = true;
    },
    submitPosition() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      let params = { ...this.postForm };
      if (this.currentId) {
        params.id = this.currentId;
      }
      try {
        const { data } = await editBannerPosition(params);
        this.$message.success("操作成功");
        this.clearPosition();
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
