import axios from "axios";
import config from "@/configs/index";

const PRE_URL = config.openHost;

// 获取活动异常名单
export const fetchAbnormalList = (params) => {
  return axios({
    url: PRE_URL + "/v1/user/list/activity_abnormal/get",
    method: "post",
    data: params,
  });
};

// 获取活动异常名单详情
export const fetchAbnormalInfo = (params) => {
  return axios({
    url: PRE_URL + "/v1/user/list/activity_abnormal/get_detail",
    method: "post",
    data: params,
  });
};

// 添加活动白名单
export const postWhiteList = (params) => {
  return axios({
    url: PRE_URL + "/v1/user/list/activity_white/add",
    method: "post",
    data: params,
  });
};

// 删除活动白名单
export const deleteWhite = (params) => {
  return axios({
    url: PRE_URL + "/v1/user/list/activity_white/delete",
    method: "post",
    data: params,
  });
};

// 添加活动黑名单
export const postBlackList = (params) => {
  return axios({
    url: PRE_URL + "/v1/user/list/activity_black/add",
    method: "post",
    data: params,
  });
};

// 删除活动黑名单
export const deleteBlack = (params) => {
  return axios({
    url: PRE_URL + "/v1/user/list/activity_black/delete",
    method: "post",
    data: params,
  });
};

// 校验是否已在黑名单
export const checkBlackList = (params) => {
  return axios({
    url: PRE_URL + "/v1/user/list/activity_black/check",
    method: "post",
    data: params,
  });
};

// 获取活动邮箱白名单
export const fetchWhiteListEmail = (params) => {
  return axios({
    url: PRE_URL + "/v1/user/list/activity_email_white/get",
    method: "post",
    data: params,
  });
};

// 添加活动邮箱白名单
export const postWhiteListEmail = (params) => {
  return axios({
    url: PRE_URL + "/v1/user/list/activity_email_white/add",
    method: "post",
    data: params,
  });
};

// 删除活动邮箱白名单
export const deleteWhiteListEmail = (params) => {
  return axios({
    url: PRE_URL + "/v1/user/list/activity_email_white/delete",
    method: "post",
    data: params,
  });
};

// 获取活动页列表
export const getActiveList = (params) => {
  return axios({
    url: config.url + "/phoenix/admin_activity/get_activity_list",
    method: "post",
    data: params,
  });
};
// 获取活动页详情
export const getActiveInfo = (params) => {
  return axios({
    url: config.url + "/phoenix/admin_activity/get_activity_detail",
    method: "post",
    data: params,
  });
};
// 新增/编辑活动页
export const editActive = (params) => {
  return axios({
    url: config.url + "/phoenix/admin_activity/add_or_edit_activity",
    method: "post",
    data: params,
  });
};
// 活动页上/下架/删除
export const editActiveStatus = (params) => {
  return axios({
    url: config.url + "/phoenix/admin_activity/edit_activity_status",
    method: "post",
    data: params,
  });
};
// 获取活动产品
export const getActiveProducts = (params) => {
  return axios({
    url: config.url + "/phoenix/admin_activity/get_activity_products",
    method: "post",
    data: params,
  });
};
// 获取占位代码
export const getActiveCode = (params) => {
  return axios({
    url: config.url + "/phoenix/admin_activity/get_activity_placeholder_codes",
    method: "post",
    data: params,
  });
};

export const uploadImageUrl = (params) => {
  return axios({
    url: config.url + "/phoenix/admin_activity/upload_image_from_url",
    method: "post",
    data: params,
  });
};
